<nav
  #navBar
  id="ka-navbar"
  class="navbar navbar-expand-lg navbar-light w-100 align-items-center justify-content-between pt-0"
>
  <div class="navbar-visible-container">
    <button
      class="navbar-toggler"
      type="button"
      data-bs-toggle="collapse" 
      data-bs-target="#navbarSupportedContent" 
      aria-controls="navbarSupportedContent" 
      aria-expanded="false" 
      aria-label="Toggle navigation"
      id="navbar-menu-mobile">
      <div class="navbar-toggler-icon d-flex align-items-center px-3">
        <fa-icon [icon]="['fas', 'bars']"></fa-icon>
      </div>
    </button>
    <img
      class="logoAndina d-block d-lg-none p-3"
      [src]="
        user.countryId === CountryCodes.CHILE
          ? './assets/media/logo_miandina.png'
          : './assets/media/logo_mcc.png'
      "
      [alt]="
        user.countryId === CountryCodes.CHILE
          ? 'Logo Mi Andina'
          : 'Logo Mi Coca-Cola'
      "
    />
    <div class="mobile d-block d-lg-none"></div>
  </div>
  <app-ka-notification-cart *ngIf="showCart" class="cart-notification"/>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <div class="desktop-nav w-100 d-flex flex-row justify-content-between">
      <div></div>
      <ul class="navbar-nav d-none d-lg-flex">
        <li class="nav-item dropdown pe-3">
          <app-ka-select-country [readonly]="true"></app-ka-select-country>
        </li>
      </ul>
    </div>
    <div class="d-block d-lg-none">
      <app-ka-navbar-menu-mobile></app-ka-navbar-menu-mobile>
    </div>
  </div>
</nav>

<ng-container *ngIf="isMobile && modalMobileDeliveryDateService.currentShowModal">
  <app-modal-mobile 
    [canCloseWithShadow]="modalMobileDeliveryDateService.canCloseWithShadow"
    (clickOnShadow)="modalMobileDeliveryDateService.closeModal(eventSelectDateBtn.CLOSE_BTN)">
    <app-select-delivery-date></app-select-delivery-date>
  </app-modal-mobile>
</ng-container>

<ng-container *ngIf="isMobile && modalMobileSelectCommerceService.currentShowModal">
  <app-modal-mobile 
    [canCloseWithShadow]="modalMobileSelectCommerceService.canCloseWithShadow"
    (clickOnShadow)="modalMobileSelectCommerceService.closeModal()">
    <app-ka-select-commerce></app-ka-select-commerce>
  </app-modal-mobile>
</ng-container>