import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { CommonModule } from '@angular/common';
import { Store } from '@ngrx/store';

import { ComponentsModule } from '@components/components.module';
import { VisitDate } from 'src/app/core/models/client.model';
import { OptionsFrozenDeliveryDateComponent } from '../options-frozen-delivery-date/options-frozen-delivery-date.component';

import { VisitFrozenDatesService } from 'src/app/core/services/visit-frozen-dates.service';
import { ModalMobileFrozenDeliveryDateService } from './services/modal-mobile-frozen-delivery-date.service';
import { IsMobileService } from 'src/app/core/services/is-mobile/is-mobile.service';
import { SelectFrozenDeliveryDateInfoService } from './services/select-frozen-delivery-date-info.service';

import { CurrencyPipe } from '../../pipes/currency.pipe';
import { BoxesPipe } from '../../pipes/boxes.pipe';

import { ButtonComponent } from '@components/button/button.component';

import { refreshOrderOnUpdateDate } from 'src/app/core/state/actions/cart.actions';

import { env } from 'src/app/app.component';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    ComponentsModule,
    OptionsFrozenDeliveryDateComponent,
    ButtonComponent,
    CurrencyPipe,
    BoxesPipe,
  ],
  selector: 'app-select-frozen-delivery-date',
  templateUrl: './select-frozen-delivery-date.component.html',
  styleUrls: ['./select-frozen-delivery-date.component.scss']
})
export class SelectFrozenDeliveryDateComponent implements OnInit, OnDestroy {
  private subscriptions = new Subscription;
  private selectedVisitDate: VisitDate;

  get isMobile(): boolean {
    return this.isMobileService.isMobile;
  }

  get getChapitaIconURL(): string {
    const url = '../../../../assets/icons/chapita/chapita.svg#sad-';
    return this.isMobile ? `${url}mobile` : `${url}desktop`;
  }

  get title(): string {
    return this.selectFrozenDeliveryDateInfoService.getTitle();
  }

  get subtitle(): string {
    return this.selectFrozenDeliveryDateInfoService.getSubTitle();
  }

  get frozenVisitDates(): VisitDate[] {
    return this.visitFrozenDatesService.visitDates;
  }

  get showMinAmountByDates(): boolean {
    return env.getConfigByCountry()?.showMinAmountByDates;
  }

  @Output() closeModalDesktopEvent = new EventEmitter<void>;

  constructor(
    private store: Store,
    public visitFrozenDatesService: VisitFrozenDatesService,
    private modalMobileFrozenDeliveryDate: ModalMobileFrozenDeliveryDateService,
    private isMobileService: IsMobileService,
    private selectFrozenDeliveryDateInfoService: SelectFrozenDeliveryDateInfoService,
  ) { }

  ngOnInit(): void {
    this.getFrozenVisitDates();
    this.updateFrozenVisitDate();
  }

  private getFrozenVisitDates(): void {
    this.subscriptions.add(
      this.visitFrozenDatesService.getVisitDatesByClient()
    );
  }

  private updateFrozenVisitDate(): void {
    this.store.dispatch(refreshOrderOnUpdateDate());
    this.visitFrozenDatesService.updateVisitDate();
  }

  closeModal(): void {
    this.visitFrozenDatesService.setOperationDate();
    this.visitFrozenDatesService.updateVisitDateBySelect(
      this.selectedVisitDate.visitDate
    );

    this.isMobile
      ? this.modalMobileFrozenDeliveryDate.closeModal()
      : this.closeModalDesktopEvent.emit();
  }

  updateSelectedDate(selectedVisitDate: VisitDate): void {
    this.selectedVisitDate = selectedVisitDate;
  }

  ngOnDestroy(): void {
    this.selectFrozenDeliveryDateInfoService.resetTexts();
    this.subscriptions?.unsubscribe();
  }
}
