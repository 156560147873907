<div 
  class="d-none d-lg-flex ka-grey-card" 
  *ngIf="cartHasDeliveryNotFrozenProducts && !cart.offRoute && cart.minPurchase !== 0">
  <div class="item">
    <div class="image red-circle">
      <svg class="mx-auto tabler-bucket">
        <use xlink:href="./assets/icons/tabler-sprite.svg#tabler-bucket"/>
      </svg>
    </div>
    <div class="content">
      <div class="text">
        <span>{{ ROOT + 'MINIMUM_PURCHASE' | translate }} </span>
        <span>{{ cart.minPurchase | kaCurrency }}</span>
      </div>
      <p class="my-2">
        <ngb-progressbar 
          [type]="percentAmount >= 100 ? ProgressBarStatus.COMPLETED : ProgressBarStatus.INCOMPLETED" 
          [value]="percentAmount">
        </ngb-progressbar>
      </p>
    </div>
  </div>
</div>

<div 
  class="d-flex d-lg-none mobile" 
  *ngIf="cartHasDeliveryNotFrozenProducts && !cart.offRoute && cart.minPurchase !== 0">
  <div class="item">
    <div class="content">
      <div class="text">
        <span>{{ ROOT + 'MINIMUM_PURCHASE' | translate }} </span>
        <span>{{ cart.minPurchase | kaCurrency }}</span>
      </div>
      <p class="my-2">
        <ngb-progressbar 
          [type]="percentAmount >= 100 ? ProgressBarStatus.COMPLETED : ProgressBarStatus.INCOMPLETED" 
          [value]="percentAmount">
        </ngb-progressbar>
      </p>
    </div>
  </div>
</div>

<div 
  class="d-none d-lg-flex ka-grey-card mt-3" 
  *ngIf="cartHasDeliveryFrozenProducts && !cart.offRoute && cart.minPurchaseFrozen !== 0">
  <div class="item">
    <div class="image red-circle">
      <img class="w-100" alt="CONGELADOS" src="../../../../../assets/media/icons/CONGELADOS.png" />
    </div>
    <div class="content">
      <div class="text">
        <span>{{ ROOT + 'MINIMUM_PURCHASE_FROZEN' | translate }} </span>
        <span>{{ cart.minPurchaseFrozen | kaCurrency }}</span>
      </div>
      <p class="my-2">
        <ngb-progressbar 
          [type]="percentAmountFrozen >= 100 ? ProgressBarStatus.COMPLETED : ProgressBarStatus.INCOMPLETED" 
          [value]="percentAmountFrozen">
        </ngb-progressbar>
      </p>
    </div>
  </div>
</div>

<div 
  class="d-flex d-lg-none mobile" 
  *ngIf="cartHasDeliveryFrozenProducts && !cart.offRoute && cart.minPurchaseFrozen !== 0">
  <div class="item">
    <div class="content">
      <div class="text">
        <span>{{ ROOT + 'MINIMUM_PURCHASE_FROZEN' | translate }} </span>
        <span>{{ cart.minPurchaseFrozen | kaCurrency }}</span>
      </div>
      <p class="my-2">
        <ngb-progressbar 
          [type]="percentAmountFrozen >= 100 ? ProgressBarStatus.COMPLETED : ProgressBarStatus.INCOMPLETED" 
          [value]="percentAmountFrozen">
        </ngb-progressbar>
      </p>
    </div>
  </div>
</div>


<div 
  class="d-none d-lg-flex ka-grey-card" 
  *ngIf="cart.offRoute">
  <div class="item">
    <div class="image red-circle">
      <svg class="mx-auto tabler-bucket">
        <use xlink:href="./assets/icons/tabler-sprite.svg#tabler-bucket" />
      </svg>
    </div>
    <div class="content">
      <div class="text">
        <span>{{ ROOT + 'MINIMUM_BOXES' | translate }} </span>
        <span>{{ cart.minBoxesUser }}</span>
      </div>
      <p class="my-2">
        <ngb-progressbar 
          [type]="percentNumberBoxes >= 100 ? ProgressBarStatus.COMPLETED : ProgressBarStatus.INCOMPLETED" 
          [value]="percentNumberBoxes">
        </ngb-progressbar>
      </p>
    </div>
  </div>
</div>

<div 
  class="d-flex d-lg-none mobile" 
  *ngIf="cart.offRoute">
  <div class="item">
    <div class="content">
      <div class="text">
        <span>{{ ROOT + 'MINIMUM_BOXES' | translate }} </span>
        <span>{{ cart.minBoxesUser }}</span>
      </div>
      <p class="my-2">
        <ngb-progressbar 
          [type]="percentNumberBoxes >= 100 ? ProgressBarStatus.COMPLETED : ProgressBarStatus.INCOMPLETED" 
          [value]="percentNumberBoxes">
        </ngb-progressbar>
      </p>
    </div>
  </div>
</div>
