import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IsMobileService } from 'src/app/core/services/is-mobile/is-mobile.service';

import { EventSelectDateBtn } from 'src/app/shared/components/select-delivery-date/enums/select-delivery-dates.enum';
import { ModalDesktopDeliveryDateService } from 'src/app/shared/components/select-delivery-date/services/modal-desktop-delivery-date.service';
import { ModalMobileDeliveryDateService } from 'src/app/shared/components/select-delivery-date/services/modal-mobile-delivery-date.service';

@Injectable({
  providedIn: 'root'
})
export class ShowModalDeliveryDateService {
  private modalDesktopDeliveryDateService = inject(ModalDesktopDeliveryDateService);
  private modalMobileDeliveryDateService = inject(ModalMobileDeliveryDateService);
  private isMobileService = inject(IsMobileService);

  private get isMobile(): boolean {
    return this.isMobileService.isMobile;
  }

  openModal$(): Observable<EventSelectDateBtn> {
    if (this.isMobile) {
      if (this.modalMobileDeliveryDateService.currentShowModal) {
        this.modalMobileDeliveryDateService.closeModal(EventSelectDateBtn.CLOSE_BTN)
      }

      this.modalMobileDeliveryDateService.openModal();
      return this.modalMobileDeliveryDateService.isCloseModal$;
    }

    if (this.modalDesktopDeliveryDateService.modalRef) {
      this.modalDesktopDeliveryDateService.closeModal(EventSelectDateBtn.CLOSE_BTN);
    }
    return this.modalDesktopDeliveryDateService.openModal().closed;
  }

  enableBackdrop(): void {
    if (this.isMobile) {
      this.modalMobileDeliveryDateService.canCloseWithShadow = true;
      return;
    }

    this.modalDesktopDeliveryDateService.backdrop = true;
  }

  disableBackdrop(): void {
    if (this.isMobile) {
      this.modalMobileDeliveryDateService.canCloseWithShadow = false;
      return;
    }

    this.modalDesktopDeliveryDateService.backdrop = 'static';
  }
}
