import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
  NO_ERRORS_SCHEMA,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { NgxSpinnerModule } from 'ngx-spinner';

import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { metaReducers, reducers } from './core/state/app.state';
import { CartEffects } from './core/state/effects/cart.effects';
import { ClientEffects } from './core/state/effects/client.effects';
import { UserEffects } from './core/state/effects/user.effects';
import { UiEffects } from './core/state/effects/ui.effects';

import { LoyaltyService } from './core/services/loyalty/loyalty.service';
import { SubmenuService } from './shared/components/ka-sidebar/services/submenu.service';

import { SharedModule } from './shared/shared.module';
import { createTranslateLoader } from './shared/utils/translate-http-loader';
import { EnvironmentUtils } from 'src/environments/utils/env.class';
import { PortfolioEffects } from './core/state/effects/portfolio.effects';
import { NewslettersComponent } from './pages/newsletters/newsletters.component';


@NgModule({
    declarations: [AppComponent, NewslettersComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        SharedModule,
        CoreModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient],
            },
        }),
        StoreModule.forRoot(reducers, {
            metaReducers,
            runtimeChecks: {
                strictStateImmutability: false,
                strictActionImmutability: false,
            },
        }),
        EffectsModule.forRoot([
            CartEffects,
            ClientEffects,
            UserEffects,
            UiEffects,
            PortfolioEffects,
        ]),
        StoreDevtoolsModule.instrument({
            name: 'Andina App Devtools',
            maxAge: 25,
            logOnly: environment.production,
        }),
        NgxSpinnerModule,
        GoogleTagManagerModule.forRoot({
            id: environment.GTM_ID,
            gtm_auth: environment.GTM_AUTH,
            gtm_preview: environment.GTM_PREVIEW,
        }),
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.enableServiceWorker,
            registrationStrategy: 'registerWhenStable:5000',
        }),
    ],
    providers: [
        EnvironmentUtils,
        LoyaltyService,
        SubmenuService,
        provideHttpClient(withInterceptorsFromDi())
    ],
    bootstrap: [AppComponent],
    schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA], 
})
export class AppModule {}
