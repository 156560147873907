import { Pipe, PipeTransform } from '@angular/core';
import { Cart } from '../../core/models/cart.model';
import { CartService } from 'src/app/core/services/cart.service';
import { Store } from '@ngrx/store';
import { hasCartDeliveryFrozenProducts } from 'src/app/core/state/selectors/cart.selectors';

@Pipe({
  name: 'isProductFrozen',
})
export class IsProductFrozenPipe implements PipeTransform {

  cartHasDeliveryFrozenProducts?: boolean;

  constructor(
    private cartService: CartService,
    private store: Store<{ user: Cart }>
  ) {
    this.store.select(hasCartDeliveryFrozenProducts).subscribe((hasDeliveryFrozenProducts) => (this.cartHasDeliveryFrozenProducts = hasDeliveryFrozenProducts));
  }

  transform(_cart: Cart): unknown {
    const minPurchaseFrozenReached = this.cartHasDeliveryFrozenProducts ? this.cartService.isFrozenProductsMinAmountCompleted() : true;
    return !this.cartHasDeliveryFrozenProducts || minPurchaseFrozenReached;
  }
}
