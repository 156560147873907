export enum EndpointsCodes {
  AWS_LOGIN = 'AWS_LOGIN',
  AWS_CONFIRM_SIGN_UP = 'AWS_CONFIRM_SIGN_UP',
  AWS_SEND_CONFIRM_CODE = 'AWS_SEND_CONFIRM_CODE',
  AWS_RESEND_SIGN_UP = 'AWS_RESEND_SIGN_UP',
  GET_LOCATION_BY_IP = 'GET_LOCATION_BY_IP',
  GET_USER_NAME = 'GET_USER_NAME',
  GET_USER_INFO = 'GET_USER_INFO',
  INIT_CLIENT_SESSION = 'INIT_CLIENT_SESSION',
  POST_USER_DEVICE_INFO = 'POST_USER_DEVICE_INFO',
  SET_OPERATION_DATE = 'SET_OPERATION_DATE',
  POST_USER = 'POST_USER',
  UPDATE_USER_PWD = 'UPDATE_USER_PWD',
  CHANGE_USER_PWD = 'CHANGE_USER_PWD',
  GET_USER_STATUS = 'GET_USER_STATUS',
  GET_VISIT_PLAN = 'GET_VISIT_PLAN',
  GET_INVOICE_DEADLINE = 'GET_INVOICE_DEADLINE',
  GET_ORDERS_HISTORY = 'GET_ORDERS_HISTORY',
  GET_ORDER_DETAIL = 'GET_ORDER_DETAIL',
  POST_ORDER = 'POST_ORDER',
  POST_PARTIAL_ORDER = 'POST_PARTIAL_ORDER',
  POST_REPEAT_ORDER = 'POST_REPEAT_ORDER',
  GET_PORTFOLIO_PROD = 'GET_PORTFOLIO_PROD',
  GET_SUGGESTED_PROD = 'GET_SUGGESTED_PROD',
  GET_DISCOUNTS_PROD = 'GET_DISCOUNTS_PROD',
  GET_PROD_FILTERS = 'GET_PROD_FILTERS',
  GET_PORTFOLIO_FILTERS = 'GET_PORTFOLIO_FILTERS',
  GET_PROD_LABELS = 'GET_PROD_LABELS',
  GET_DISCRETIONARY_DISCOUNT = 'GET_DISCRETIONARY_DISCOUNT_LABEL',
  GET_CREDITS = 'GET_CREDITS',
  PUT_ORDER_CREDITS = 'PUT_ORDER_CREDITS',
  TYC_SERVICE = 'TYC_SERVICE',
  POST_CANCEL_ORDER = 'POST_CANCEL_ORDER',
  GET_DRAFT_ORDER = 'GET_DRAFT_ORDER',
  DELETE_DRAFT_ORDER = 'DELETE_DRAFT_ORDER',
  GET_INVOICE_ORDER = 'GET_INVOICE_ORDER',
  GET_DISCOUNT_CALCULATION = 'GET_DISCOUNT_CALCULATION',
  GET_PAYMENT_METHOD = 'GET_PAYMENT_METHOD',
  LOGIN_INTEGRATIONS = 'LOGIN_INTEGRATIONS',
  LOGIN_EXTERNAL_INTEGRATIONS = 'LOGIN_EXTERNAL_INTEGRATIONS',
  POST_EXTERNAL_USER_INFO = 'POST_EXTERNAL_USER_INFO',
  POST_EXTERNAL_USER_SESSION = 'POST_EXTERNAL_USER_SESSION',
  GET_MISSING_BOTTLES_INFORMATION = 'GET_MISSING_BOTTLES_INFORMATION',
  GET_LAST_ORDER = 'GET_LAST_ORDER',
  STOCK_DIGITAL = 'STOCK_DIGITAL',
  GET_CLIENT = 'GET_CLIENT',
  MISSING_ON_DRAFT = 'MISSING_ON_DRAFT',
  POST_FAVORITE ='POST_FAVORITE',
  DELETE_FAVORITE = 'DELETE_FAVORITE',
  SAVE_NPS_SURVEY = 'SAVE_NPS_SURVEY',
  GET_BANNERS = 'GET_BANNERS',
  GET_OPTIONS_MET = 'GET_OPTIONS_MET',
  POST_VALIDATE_CODE_LOYALTY = 'POST_VALIDATE_CODE_LOYALTY',
  POST_REDEEM_CODE_LOYALTY = "POST_REDEEM_CODE_LOYALTY",
  GET_BANNER_CODE_LOYALTY = "GET_BANNER_CODE_LOYALTY",
  GET_NEWSLETTER_BY_CLIENT_ID = "GET_NEWSLETTER_BY_CLIENT_ID",
  GET_EXCHANGE_HISTORY = "GET_EXCHANGE_HISTORY",
  POST_NEWSLETTER_SET_STATUS = "POST_NEWSLETTER_SET_STATUS",
  GET_AUTOCOMPLETE_PRODUCTS = "GET_AUTOCOMPLETE_PRODUCTS"
}
