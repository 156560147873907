import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CountryCodes } from 'src/app/core/enums/country-codes.enum';
import { Discount } from 'src/app/core/models/discount.model';
import { Store } from '@ngrx/store';
import { Cart } from 'src/app/core/models/cart.model';
import { UserInfo } from 'src/app/core/models/user-info.model';
import { upsertMultipleProducts } from 'src/app/core/state/actions/cart.actions';
import { Subscription } from 'rxjs';
import { getProductsAndDiscounts } from 'src/app/core/state/selectors/cart.selectors';
import { DiscountCalculationType } from 'src/app/core/enums/discount-calculation-type';
import { CartService } from 'src/app/core/services/cart.service';
import { environment } from 'src/environments/environment';
import { DefaultImages } from 'src/app/core/enums/default-images';
import { TranslateService } from '@ngx-translate/core';
import { Client } from 'src/app/core/models/client.model';
import { DiscountTypes } from 'src/app/core/enums/discount-types';

@Component({
  selector: 'app-scale-pack-modal',
  templateUrl: './scale-pack-modal.component.html',
  styleUrls: ['./scale-pack-modal.component.scss'],
})
export class ScalePackModalComponent implements OnInit, OnDestroy {
  readonly ROOT_LANG = 'NEW_ORDER.DISCOUNTS.SCALE.';
  readonly pillMsg = 'NEW_ORDER.DISCOUNTS.SCALE.SAVE';
  readonly DiscountCalculationType = DiscountCalculationType;
  readonly DiscountTypes = DiscountTypes;
  readonly CountryCodes = CountryCodes;
  private subscriptions = new Subscription();
  @Input() public discount: Discount;
  user: UserInfo;
  currentScale;
  nextScale;
  prevScale;
  isCurrentScaleLast;
  currentScaleLabel: string;
  totalFinalPrice = 0;
  totalListPrice = 0;
  cartService: CartService;
  notFoundImg = environment.WEB_DOMAIN + DefaultImages.PRODUCT;
  img_root = environment.WEB_DOMAIN;
  client: Client;

  constructor(
    private store: Store<{ user: UserInfo; cart: Cart; client: Client }>,
    public activeModal: NgbActiveModal,
    cartService: CartService,
    private translateService: TranslateService,
  ) {
    this.cartService = cartService;
    this.subscriptions.add(
      this.store.select('user').subscribe((user) => (this.user = user)),
    );
    this.subscriptions.add(
      this.store.select(getProductsAndDiscounts).subscribe(() => this.closeModal()),
    );
    this.subscriptions.add(
      this.store.select('client').subscribe((client) => (this.client = client)),
    );
  }

  ngOnInit(): void {
    this.setInitialQuantities();
    this.updateScale();
  }

  updateScale(): any {
    const currentScale = this.discount.requirements?.scales?.find((scale) => {
      return (
        this.discount.quantitySelected >= scale.min &&
        this.discount.quantitySelected <= scale.max
      );
    });
    this.currentScale = currentScale
      ? currentScale
      : this.discount.requirements?.scales[0];
    this.getScaleLabel();
    this.getScaleDiscountPrice();
  }

  getScaleLabel(): void {
    const scaleIndex = this.discount.requirements.scales.findIndex(
      (scale) => scale == this.currentScale,
    );
    this.nextScale = this.discount.requirements.scales[scaleIndex + 1];
    this.prevScale = this.discount.requirements.scales[scaleIndex - 1];
    this.isCurrentScaleLast = this.currentScale?.max === 9999999;
    const currentScaleHasDiscount = !!this.currentScale?.reward?.value;
    const nextScaleHasDiscount = !!this.nextScale?.reward?.value;

    if (this.isCurrentScaleLast) {
      if (currentScaleHasDiscount)
        this.currentScaleLabel = 'DISCOUNT_LABEL_UNLIMITED';
      return;
    }

    if (this.currentScale?.max === 999999999) {
      this.currentScaleLabel = 'DISCOUNT_LABEL_UNIT_3';
      return;
    }

    if (!currentScaleHasDiscount) {
      this.currentScaleLabel =
        this.discount.calculationType === DiscountCalculationType.AMOUNT
          ? 'NO_DISCOUNT_LABEL_2'
          : 'NO_DISCOUNT_LABEL_1';
      return;
    }

    if (
      this.discount.quantitySelected < this.currentScale?.max - 1 ||
      !nextScaleHasDiscount
    ) {
      this.currentScaleLabel = 'DISCOUNT_LABEL_PACK';
    } else if (
      this.currentScale?.max === this.discount.quantitySelected ||
      !nextScaleHasDiscount
    ) {
      this.currentScaleLabel = 'PRE_DISCOUNT_MAX';
    } else {
      this.currentScaleLabel = 'PRE_DISCOUNT';
    }
  }

  getScaleDiscountPrice(): void {
    let discountAcc;
    let previousListPrice;
    if (!this.currentScale.reward?.value) {
      previousListPrice = this.totalListPrice;
      discountAcc = this.totalListPrice - this.totalFinalPrice;
    }
    this.totalFinalPrice = 0;
    this.totalListPrice = 0;
    this.currentScale.products.forEach((product) => {
      this.totalFinalPrice += product.quantity * product.price.finalPrice;
      this.totalListPrice +=
        product.quantity * product.price.finalPriceWithoutDiscount;
    });
    // if scale has no reward and is upper scale
    if (
      !this.currentScale.reward?.value &&
      previousListPrice < this.totalListPrice
    )
      this.totalFinalPrice = this.totalFinalPrice - discountAcc;
  }

  addProduct(product): void {
    this.discount.quantitySelected++;
    this.discount.requirements.scales.forEach((scale) => {
      const productUpdated = scale.products.find(
        (prod) => prod.productId === product.productId,
      );
      productUpdated.quantity++;
    });
    this.updateScale();
  }

  removeProduct(product): void {
    if (product.quantity > 0) {
      this.discount.quantitySelected--;
      this.discount.requirements.scales.forEach((scale) => {
        const productUpdated = scale.products.find(
          (prod) => prod.productId === product.productId,
        );
        productUpdated.quantity--;
      });
      this.updateScale();
    }
  }

  addProductsToCart(): void {
    if (!this.discount.quantitySelected) return;
    const productsPack = [];
    this.currentScale.products?.forEach((product) => {
      const enabledToSellBySubUnit = this.client?.subUnitAvailable;
      const subunitSelected = enabledToSellBySubUnit
        ? product.subunitSelected
        : null;
      if (product.quantity > 0)
        productsPack.push({
          ...product,
          quantitySelected: product.quantity,
          erpMesureUnitId: product.erpMeasureUnitId,
          enabledToSellBySubUnit,
          subunitSelected,
        });
    });
    this.store.dispatch(upsertMultipleProducts({ products: productsPack }));
    this.cartService.updateDeliveryProducts();
  }

  closeModal(): void {
    this.setInitialQuantities();
    this.activeModal.close();
  }

  setInitialQuantities(): void {
    if (!this.discount) return;
    this.discount.quantitySelected = 0;
    this.discount?.requirements?.scales?.forEach((scale) => {
      scale.products.forEach((prod) => {
        prod.quantity = 0;
        prod.erpMeasureUnitId = 'CS';
        prod.subunitSelected = 'CS';
        prod.enabledToSellBySubUnit = this.client.subUnitAvailable;
      });
    });
  }

  // hasDiscount(scale): boolean {
  //   return !!scale?.reward?.value;
  //   // return scale.products.every((product) => {
  //   //   return product.price.finalPrice < product.price.listPrice;
  //   // });
  // }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onImgError(event): void {
    event.target.src = this.notFoundImg;
  }

  // private getTotalQuantity = (scales): number => {
  //   //TODO es obligatorio que esten todos los productos seleccionados ?
  //   return _.reduce(
  //     scales,
  //     (acc, cur) => {
  //       return acc + cur.products.length;
  //     },
  //     0
  //   );
  // };
}
