<div [class]="data.customClass">
    <div class="modal-header">
      <button 
        *ngIf="!data.hideCloseButton" 
        type="button" 
        class="btn-close" 
        data-bs-dismiss="modal" 
        aria-label="Close" 
        (click)="activeModal.dismiss('Cross click')">
      </button>
    </div>
    
    <div class="modal-body">

        <h2 class="haveNews">{{ 'HOME.NEWSLETTERS_POPUP.TITLE' | translate}}</h2>

        <div class="popUpImg-box d-flex justify-content-center align-items-center">
            <img *ngIf="screenWidth > 767" class="popUpImg mb-3" [src]="getOptimizedImageUrl(newsletterImgDsk)" alt="popUp img" (error)="onImageError($event,newsletterImgDsk)"/>
            <img *ngIf="screenWidth <= 767" class="popUpImg mb-3" [src]="getOptimizedImageUrl(newsletterImgMb)" alt="popUp img" (error)="onImageError($event,newsletterImgMb)"/>
        </div>
<br>
        <h3 *ngIf="activeNewsletter.title" class="title my-2">{{activeNewsletter.title}}</h3>

        <div *ngIf="newsletters.length > 1" class="d-flex justify-content-between mt-3 arrowBox">
            <button type="button" class="btn btn-secondary arrow" (click)="previousNewsletter()">
                <img src="../../../../assets/icons/arrow-left.svg" alt="" class="arrow-left">
            </button>
            <button type="button" class="btn btn-secondary arrow" (click)="nextNewsletter()">
                <img src="../../../../assets/icons/arrow-right.svg" alt="" class="arrow-left">
            </button>
        </div>
               
        <p *ngIf="activeNewsletter.summary" class="description">{{activeNewsletter.summary}}</p>

    </div>
    <div *ngIf="newsletters.length > 1" class="d-flex justify-content-center align-items-center gap-3 mt-3">
        <span 
            *ngFor="let newsletter of newsletters; let i = index" 
            class="dot" 
            [class.active]="i === newsletterIndex"
            >
        </span>
    </div>
<br>
    <div *ngIf="showFooterButtons" class="modal-footer d-flex justify-content-center flex-row gap-5">
        <button
            type="button"
            class="btn-square border-0 buttons rejectBtn"
            [ngClass]="{ 'btn-wide': data.btnWide }"
            (click)="reject()"
        >
        {{ 'HOME.NEWSLETTERS_POPUP.OMIT' | translate}}
        </button>
        <button
            class="btn-square-black buttons confirmBtn"
            [ngClass]="{ 'btn-wide': data.btnWide }"
            (click)="confirm()"
        >
        {{ 'HOME.NEWSLETTERS_POPUP.CONFIRM' | translate}}
        </button>
    </div>
    
    
  </div>
