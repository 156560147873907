import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import { Cart } from '../models/cart.model';
import { Client } from '../models/client.model';
import { UserInfo } from '../models/user-info.model';

import { env } from 'src/app/app.component';
import { FEATURES } from 'src/environments/utils/env.model';
import { CountryCodes } from '../enums/country-codes.enum';

@Injectable({
  providedIn: 'root',
})
export class PrincipalMenuService {
  readonly CountryCodes = CountryCodes;
  client: Client;
  cart: Cart;
  user: UserInfo;
  private subscriptions = new Subscription();
  readonly digitalPaymentsURL = `${
    env.getConfigByCountry().digitalPaymentsUrl
  }/sign-in-easy-pay`;

  constructor(
    private store: Store<{ user: UserInfo; client: Client; cart: Cart }>,
  ) {
    this.subscriptions.add(
      this.store.select('cart').subscribe((cart) => (this.cart = cart)),
    );
    this.subscriptions.add(
      this.store.select('user').subscribe((user) => (this.user = user)),
    );
    this.subscriptions.add(
      this.store.select('client').subscribe((client) => {
        this.client = client;
        this.getPrincipalMenuItems().forEach((opt) => {
          if (opt.icon === 'shopping-cart')
            opt.disabled =
              this.client.hasLockOrder || !this.client.data.visitDates.length;
        });
      }),
    );
  }

  getPrincipalMenuItems(isMobile?) {
    return [
      {
        active: false,
        routerLink: 'home',
        icon: 'home',
        langKey: 'START',
        disabled: false,
        show: true,
      },
      {
        active: false,
        routerLink: 'mi-perfil',
        icon: 'users',
        langKey: 'MY_PROFILE',
        disabled: false,
        show: true,
      },
      {
        active: false,
        routerLink: 'mis-pedidos/:clientId',
        icon: 'bucket',
        langKey: `MY_ORDERS_${isMobile ? 'MOBILE' : 'DESKTOP'}`,
        disabled: false,
        show: true,
      },
      {
        active: false,
        routerLink: 'nuevo-pedido/1',
        icon: 'shopping-cart',
        langKey: `NEW_ORDER_${isMobile ? 'MOBILE' : 'DESKTOP'}`,
        disabled: this.client.hasLockOrder || this.client.data.hasCreditLock,
        show: true,
      },
      {
        active: false,
        routerLink: 'mis-promociones',
        icon: 'discount-2',
        langKey: 'MY_BENEFITS',
        disabled: false,
        show: false,
      },
      {
        active: false,
        routerLink: 'mi-saldo',
        icon: 'wallet',
        langKey: 'MY_WALLET',
        disabled: false,
        show: env.isFeatureAvailable(FEATURES.MY_WALLET)
      },
      {
        active: false,
        routerLink: 'pago-rapido',
        icon: 'file-invoice',
        langKey: 'MY_DIGITAL_PAYMENTS',
        disabled: false,
        external: false,
        show: this.client.showPPD
      },
      {
        active: false,
        routerLink: 'my-tickets',
        icon: this.user.countryId === this.CountryCodes.PARAGUAY ? 'request' : 'ticket',
        langKey: 'MY_TICKETS',
        show: env.isFeatureAvailable(FEATURES.CRM),
      },
      {
        active: false,
        icon: 'hand-shake',
        langKey: 'MY_REWARDS',
        show: env.isFeatureAvailable(FEATURES.LOYALTY),
        options: [],
        open: false,
      },
      {
        active: false,
        routerLink: 'digital-promotions',
        icon: 'discount-2',
        langKey: 'DIGITAL_PROMOTIONS',
        disabled: false,
        show: env.isFeatureAvailable(FEATURES.DIGITAL_PROMOTIONS),
      },
      {
        active: false,
        routerLink: 'newsletters',
        icon: 'news',
        langKey: 'NEWSLETTERS',
        disabled: false,
        show: env.isFeatureAvailable(FEATURES.NEWSLETTERS),
      },
    ];
  }
}
