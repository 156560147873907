import { Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { EventSelectDateBtn } from '../enums/select-delivery-dates.enum';

import { SelectDeliveryDateDesktopComponent } from '../../select-delivery-date-desktop/select-delivery-date-desktop.component';

@Injectable({
  providedIn: 'root'
})
export class ModalDesktopDeliveryDateService {
  modalRef: NgbModalRef;
  backdrop: boolean | 'static' = 'static';

  constructor(
    private ngbModalService: NgbModal,
  ) { }

  openModal(): NgbModalRef {
    this.modalRef = this.ngbModalService.open(SelectDeliveryDateDesktopComponent, { 
      centered: true,
      size: 'sm',
      backdrop: this.backdrop,
      container: 'app-main',
      windowClass: 'select-visit-date-desktop',
    });

    return this.modalRef;
  }

  closeModal(btnEvent: EventSelectDateBtn): void {
    this.modalRef.close(btnEvent);
  }
}