import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { Cart } from 'src/app/core/models/cart.model';
import { Product } from 'src/app/core/models/product.model';
import * as CartActions from 'src/app/core/state/actions/cart.actions';
import { CartService } from 'src/app/core/services/cart.service';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { delay } from 'rxjs/operators';
import { hasCartDeliveryFrozenProducts } from 'src/app/core/state/selectors/cart.selectors';

@Component({
    selector: 'app-ka-modify-product-quantity',
    templateUrl: './ka-modify-product-quantity.component.html',
    styleUrls: ['./ka-modify-product-quantity.component.scss']
})
export class KaModifyProductQuantityComponent implements OnInit, OnDestroy {
    readonly ROOT_LANG = 'NEW_ORDER.BUTTON_ADD.';
    private subscriptions = new Subscription();
    @Input() product?: Product;
    cart: Cart;
    pill = 0;
    unavailable = false;
    minSubunit: number;
    isSubunit: boolean;
    previousPill: number;
    maxOrderQuantity: number;
    cartHasDeliveryFrozenProducts?: boolean;

    @ViewChild('maxQuantityTooltip') maxQuantityTooltip: NgbTooltip;
    
    constructor(
      private store: Store<{ cart: Cart }>,
      private cartService: CartService,
      private gtmService: GoogleTagManagerService,
    ) {
      this.subscriptions.add(this.store.select(hasCartDeliveryFrozenProducts).subscribe((hasDeliveryFrozenProducts) => (this.cartHasDeliveryFrozenProducts = hasDeliveryFrozenProducts)));
    }

    ngOnInit(): void {
        this.subscriptions.add(this.store.select('cart').subscribe((cart) => {
            this.cart = cart;
            const sameProdSamesubunit = cart.products.find(product => product.productId === this.product.productId && product.subunitSelected === this.product.erpMeasureUnitId);
             if(sameProdSamesubunit) {
                this.pill = sameProdSamesubunit?.quantity || 0;
            } else {
                this.pill = cart.products.find(product => product.productId === this.product.productId)?.quantity || 0;
            }
        }));
        this.unavailable = this.product.locked;
        this.isSubunit = this.product.erpMeasureUnitId === 'BOT';
        this.minSubunit = this.isSubunit && this.product.subUnit.multiple ? this.product.subUnit.multiple : 1;
        this.determineMaxOrderQuantity();
    }

    addOneProductToCart():void{
        if (this.pill + this.minSubunit > this.maxOrderQuantity || !this.pill) return;
        const cartProduct = {...this.product, quantitySelected: this.isSubunit ? this.minSubunit : 1};
        if (this.product.deliveryType === 'deliveryfrozen' && !this.cartHasDeliveryFrozenProducts) {
            this.store.dispatch(CartActions.deliveryFrozenActions({product: cartProduct}));
        } else {
            this.store.dispatch(CartActions.upsertProduct({product : cartProduct}));
            this.cartService.updateDeliveryProducts();
        }
    }

    addProductToCart(): void {
        if (this.pill > this.maxOrderQuantity) return;
        const cartProduct = { ...this.product};
        if (this.product.deliveryType === 'deliveryfrozen' && !this.cartHasDeliveryFrozenProducts) {
            this.store.dispatch(CartActions.deliveryFrozenActions({product: cartProduct}));
        } else {
            this.store.dispatch(CartActions.upsertProduct({ product: cartProduct }));
            this.cartService.updateDeliveryProducts();
        }
    }

    removeOneProductFromCart(): void {
        if (this.isSubunit && this.pill - this.minSubunit < this.minSubunit) return;
        if (!this.isSubunit && this.pill - 1 < 1) return;
        if (this.pill > 1) {
            this.gtmService.pushTag({ event: 'removeProducts', products: { ...this.product, quantitySelected: this.isSubunit ? this.minSubunit : 1 } });
            const cartProduct = { ...this.product, quantitySelected: this.isSubunit ? -this.minSubunit : -1 };
            this.store.dispatch(CartActions.upsertProduct({ product: cartProduct }));
            this.cartService.updateDeliveryProducts();
        }else{
            this.gtmService.pushTag({ event: 'removeProducts', products: this.product });
            this.store.dispatch(CartActions.deleteProduct({ product: this.product }));
            this.cartService.updateDeliveryProducts();
        }
    }
    
    updateQuantity(event):void {
        event.target.blur();
        const selectedPill = this.previousPill ? this.previousPill : this.pill;
        const value = parseInt(event.target.value);

        if (value === this.product.quantity) return;
        if (event.target.value.length === 0 || value <= 0 || value > this.maxOrderQuantity) {
            event.target.value = this.pill;
            return;
        }

        if (this.product.erpMeasureUnitId !== 'BOT') {
            this.product.quantity = value;
            this.product.quantitySelected = value - selectedPill;
        }

        if (this.product.erpMeasureUnitId === 'BOT') {
            const value = event.target.value / this.minSubunit;
            const finalValue = Math.ceil(value) * this.minSubunit;
            this.product.quantity = finalValue;
            this.product.quantitySelected = finalValue - selectedPill;
        }

        if (this.product.quantity > this.maxOrderQuantity) {
            this.product.quantity = this.product.quantity - this.minSubunit;
            this.product.quantitySelected = this.product.quantity - selectedPill;
        }

        this.pill = this.product.quantity;
        event.target.value = this.pill;

        this.store.dispatch(CartActions.upsertProduct({ product: this.product}));
        this.cartService.updateDeliveryProducts();
    }

    isInteger(quantity: number): boolean {
        return Number.isInteger(quantity);
    }

    validQuantLength(event): void {
        const validKeys = ['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete'];
        if (validKeys.some((validKey) => validKey === event.key)) return;
        if (event.target.value.length > (this.maxOrderQuantity.toString().length - 1)){
            event.preventDefault();
            return;
        }
        if (this.product.quantitySelected > this.maxOrderQuantity || !/^\d$/.test(event.key)) event.preventDefault();
    }

    validMaxQuantity(event): void {
        if (Number(event.target.value) > this.maxOrderQuantity) {
            this.pill = this.maxOrderQuantity;
            this.product.quantitySelected = this.pill;
            this.showTooltipForThreeSeconds(this.maxQuantityTooltip);
            event.preventDefault();
        }
    }

    determineMaxOrderQuantity() {
        const isBottleSelected = this.product.erpMeasureUnitId === 'BOT';
        this.maxOrderQuantity = isBottleSelected
            ? this.product.maxOrderQuantityBottle 
            : this.product.maxOrderQuantityBox;
    }

    onQuantityPaste(event): void {
        const amount = parseInt(event.clipboardData.getData('text/plain'));
        this.previousPill = this.pill;
        if (amount > this.maxOrderQuantity) {
            this.pill = this.maxOrderQuantity;
            this.showTooltipForThreeSeconds(this.maxQuantityTooltip);
        } else {
            this.pill = amount;
        }
        this.product.quantitySelected = this.pill;
        event.preventDefault();
    }

    showTooltipForThreeSeconds(tooltip) {
        if (!tooltip.isOpen()) {
            of(tooltip.open()).pipe(delay(3000)).subscribe({ next: () => tooltip.close()});
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
