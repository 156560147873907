import { CommonModule } from '@angular/common';
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { Subscription, take } from 'rxjs';

import { VisitDatesService } from 'src/app/core/services/visit-date.service';
import { ShowModalDeliveryDateService } from 'src/app/core/services/show-modal-ambient-delivery-date/show-modal-ambient-delivery-date.service';

import { WeekdayDayMonthParserPipe } from 'src/app/shared/pipes/week-day-day-month.pipe';
import { VisitDate } from 'src/app/core/models/client.model';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    WeekdayDayMonthParserPipe,
  ],
  selector: 'app-show-delivery-date',
  templateUrl: './show-delivery-date.component.html',
  styleUrls: ['./show-delivery-date.component.scss'],
  host: {
    '[class.show-delivery-date]': 'true',
    '(click)': 'openModal()',
  }
})
export class ShowDeliveryDateComponent implements OnInit, OnDestroy {
  private showModalDeliveryDateService = inject(ShowModalDeliveryDateService);
  
  private subscription = new Subscription;
  get clientVisitDates(): VisitDate[] {
    return this.visitDateService.visitDates;
  }
  get currentVisitDate(): string | Date {
    return this.visitDateService.selectedVisitDate;
  }
  get isEmptyDeliveryDate(): boolean {
    return !this.clientVisitDates || this.clientVisitDates.length === 0;
  }
  get isOneDeliveryDate(): boolean {
    return this.clientVisitDates?.length === 1;
  }
  get isMoreThanOneDeliveryDate(): boolean {
    return this.clientVisitDates?.length > 1;
  }

  constructor(private visitDateService: VisitDatesService) { }

  ngOnInit(): void {
    this.getVisitDatesByClient();
  }

  private getVisitDatesByClient(): void {
    this.subscription.add(this.visitDateService.getVisitDatesByClient());
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  openModal(): void {
    this.showModalDeliveryDateService.enableBackdrop();
    this.showModalDeliveryDateService.openModal$()
      .pipe(take(1))
      .subscribe();
  }
}

