import { Injectable, OnDestroy, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { from, of, Subscription } from 'rxjs';
import {
  catchError,
  filter,
  map,
  mergeMap,
  switchMap,
  take,
  withLatestFrom,
} from 'rxjs/operators';
import { Router } from '@angular/router';

import { NewOrderService } from 'src/app/pages/new-order/services/new-order.service';
import { FavoritesService } from '../../services/favorites.service';
import { IsMobileService } from '../../services/is-mobile/is-mobile.service';
import { CartService } from '../../services/cart.service';
import { ModalsService } from '../../services/modals.service';
import { ProductsService } from '../../services/products.service';
import { VisitPlanService } from '../../services/visit-plan.service';
import { ModalMobileFrozenDeliveryDateService } from 'src/app/shared/components/select-frozen-delivery-date/services/modal-mobile-frozen-delivery-date.service';
import { ModalDesktopFrozenDeliveryDateService } from 'src/app/shared/components/select-frozen-delivery-date/services/modal-desktop-frozen-delivery-date.service';
import { ExpireDateService } from '../../services/expire-date.service';
import { ExternalDataService } from 'src/app/pages/external-integration/services/external-data.service';

import { BERespModel } from '../../models/backend/BE-response.model';
import { Cart } from '../../models/cart.model';
import { Client } from '../../models/client.model';
import { FEATURES } from 'src/environments/utils/env.model';
import { env } from '../../../../app/app.component';
import { getCartProducts, getFridayMessageShow } from '../selectors/cart.selectors';
import * as CartActions from './../actions/cart.actions';
import * as ClientActions from './../actions/client.actions';
import { VisitDatesService } from '../../services/visit-date.service';
import { VisitFrozenDatesService } from '../../services/visit-frozen-dates.service';


@Injectable({
  providedIn: 'root',
})
export class CartEffects implements OnDestroy {
  private _isMobile = inject(IsMobileService);
  private subscriptions = new Subscription();
  cart: Cart;
  client: Client;

  get isMobile(): boolean {
    return this._isMobile.isMobile;
  }

  get isMinPurchaseUpdateFeatureAvailable(): boolean {
    return env.isFeatureAvailable(FEATURES.UPDATE_MIN_PURCHASE);
  }

  constructor(
    private actions$: Actions,
    private productService: ProductsService,
    private store: Store<{ cart: Cart; client: Client; }>,
    private newOrderService: NewOrderService,
    private modalService: ModalsService,
    private cartService: CartService,
    private visitPlanService: VisitPlanService,
    private router: Router,
    private favoriteService: FavoritesService,
    private _modalMobileFrozenDeliveryDateService: ModalMobileFrozenDeliveryDateService,
    private _modalDesktopFrozenDeliveryDateService: ModalDesktopFrozenDeliveryDateService,
    private _expireDateService: ExpireDateService,
    private externalDataService: ExternalDataService,
    private visitDatesService: VisitDatesService,
    private visitFrozenDatesService: VisitFrozenDatesService,
  ) {
    this.subscriptions.add(
      this.store.select('cart').subscribe((cart) => {
        this.cart = cart;
      }),
    );
    this.subscriptions.add(
      this.store.select('client').subscribe((client) => {
        this.client = client;
      }),
    );
  }

  getProductsDiscounts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        CartActions.upsertProduct,
        CartActions.deleteProduct,
        CartActions.upsertMultipleProducts,
        CartActions.deleteAllProducts,
        CartActions.updateAllProducts,
        CartActions.deleteAllProductsNotFrozen,
        CartActions.deleteAllProductsFrozen,
        CartActions.refreshOrderOnUpdateDate,
      ),
      withLatestFrom(this.store.select(getCartProducts)),
      mergeMap(([action, products]) =>
        this.productService.getProductsDiscounts(products).pipe(
          mergeMap((res: BERespModel) =>
            from([
              CartActions.loadProductDiscountsSuccess({ data: res.data }),
              CartActions.updateFridayMessage({ fridayMessage: res.data.fridayMessage }),
            ])
          ),
          catchError((error) => of(CartActions.loadProductDiscountsError({ error }))),
        ),
      ),
    )
  );
  

  confirmOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CartActions.confirmOrder),
      mergeMap(() =>
        this.newOrderService.createOrder().pipe(
          switchMap(() => {
            const actionsToDispatch: Action[] = [];

            actionsToDispatch.push(
              ...this.updateMinAmountBySelectedDate()
            );
            
            if (this.externalDataService.isExternalIntegrationModule()) {
              this.router.navigate(['external/order-confirmed']);
            } else {
              this.cartService.changeStep(6);
            }
            actionsToDispatch.push(CartActions.confirmOrderSuccess());
        
            return from(actionsToDispatch);
          }),
          catchError((res) => {
            if (res.errorType === 'visit_plan_error') {
              if (
                res.message === 'visitPlan_not_found' ||
                res.message === 'visitPlan_date_invalid' ||
                res.message === 'visitPlan_deliverydate_invalid_date'
              ) {
                this._expireDateService.expireDeadLineDeliveryDate();
              }

              if (res.message === 'visitPlan_deliveryFrozen_invalid_date') {
                this._expireDateService.expireDeadLineFrozenDeliveryDate();
              }

              if (res.message === 'visitPlans_dates_are_invalid_for_both_deliveryTypes') {
                this._expireDateService.expireDeadLineDeliveryDate();
                this._expireDateService.expireDeadLineFrozenDeliveryDate();
              }
            } else if (res.errorType === 'client_lock_error') {
              this.store.dispatch(CartActions.loadDeliveryDates());
              this.router.navigate(['/main/home/'], {
                state: { bypassCanDeactivate: true },
              });
            } else {
              this.modalService.openRestartOrderProducts();
            }
            return of(CartActions.confirmOrderError());
          }),
        ),
      ),
    ),
  );

  private updateMinAmountBySelectedDate(): Action[] {
    const actions: Action[] = [];

    if (!this.isMinPurchaseUpdateFeatureAvailable) {
      return actions;
    }

    if (this.cartService.hasNonFrozenProducts()) {
      actions.push(
        ClientActions.updateAmbientMinAmountBySelectedDate({
          data: {
            date: this.visitDatesService.getSelectedVisitDate(),
            amountMinRoute: 0,
            showMinAmountByDates: env.getConfigByCountry()?.showMinAmountByDates,
          }
        })
      );
    }

    if(this.cartService.hasFrozenProducts()) {
      actions.push(
        ClientActions.updateFrozenMinAmountBySelectedDate({
          data: {
            date: this.visitFrozenDatesService.getSelectedVisitDate(),
            amountMinFrozen: 0,
            showMinAmountByDates: env.getConfigByCountry()?.showMinAmountByDates,
          }
        })
      );
    }

    return actions;
  }

  loadDeliveryDates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CartActions.loadDeliveryDates),
      mergeMap(() =>
        this.visitPlanService.getClientVisitPlan().pipe(
          switchMap((res: BERespModel) => [
            CartActions.updateDeliveryDate({ date: res.data[0] }),
            ClientActions.loadVisitDatesSuccess({
              visitDates: res.data,
              visitDate: res.data[0].visitDate,
            }),
            CartActions.loadDeliveryDatesSuccess(),
          ]),
          catchError(() => {
            return [CartActions.loadDeliveryDatesError()];
          }),
        ),
      ),
    ),
  );

  loadFrozenDeliveryDates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CartActions.loadFrozenDeliveryDates),
      mergeMap(() =>
        this.visitPlanService.getClientVisitPlan(true).pipe(
          switchMap((res: BERespModel) => {
            const actionsToDispatch = [
              CartActions.updateFrozenVisitDate({
                date: res.data[0].visitDate,
              }),
              ClientActions.loadFrozenVisitDatesSuccess({
                frozenVisitDates: res.data,
              }),
              CartActions.setFrozenOperationDate(),
              CartActions.deliveryFrozenActionsSuccess(),
            ];

            const isValidDate = res.data.some(
              (date) => date.visitDate === this.cart.frozenVisitDate.toString(),
            );

            if (isValidDate) {
              actionsToDispatch.push(
                CartActions.updateFrozenVisitDate({
                  date: this.cart.frozenVisitDate.toString(),
                }),
              );
            }

            return actionsToDispatch;
          }),
          catchError(() => {
            return [
              CartActions.loadFrozenDeliveryDatesError(),
              CartActions.updateFrozenVisitDate({ date: '' }),
              ClientActions.loadFrozenVisitDatesError(),
            ];
          }),
        ),
      ),
    ),
  );

  updateDeliveryDate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CartActions.updateDeliveryDate),
      mergeMap((props) =>
        this.visitPlanService.setOperationDate().pipe(
          switchMap(() => {
            return [
              CartActions.updateDeliveryDateSuccess({
                date: props.date.visitDate,
              }),
              CartActions.updateOffRoute({ offRoute: props.date.offRoute }),
              ClientActions.updateOperationDatesSuccess(),
            ];
          }),
          catchError(() => {
            return of(CartActions.updateDeliveryDateError());
          }),
        ),
      ),
    ),
  );

  deliveryFrozenActions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CartActions.deliveryFrozenActions),
      mergeMap((props) =>
        this.visitPlanService.getClientVisitPlan(true).pipe(
          switchMap((res) => {
            if (this.isMobile) {
              this._modalMobileFrozenDeliveryDateService.openModal();
              this._modalMobileFrozenDeliveryDateService.isCloseModal$
                .pipe(take(1)).subscribe({
                  next: (isClose) => {
                    if (isClose) {
                      this.store.dispatch(
                        CartActions.setFrozenOperationDateAndAddProduct({
                          product: props.product,
                        }),
                      );
                    }
                  },
                });
            } else {
              this._modalDesktopFrozenDeliveryDateService.openModal()
                .result.then(() => {
                  this.store.dispatch(
                    CartActions.setFrozenOperationDateAndAddProduct({
                      product: props.product,
                    }),
                  );
                });
            }
            return [
              CartActions.updateFrozenVisitDate({
                date: res.data[0].visitDate,
              }),
              ClientActions.loadFrozenVisitDatesSuccess({
                frozenVisitDates: res.data,
              }),
              CartActions.deliveryFrozenActionsSuccess(),
            ];
          }),
          catchError(() => {
            this.isMobile
              ? this._modalMobileFrozenDeliveryDateService.openModal()
              : this._modalDesktopFrozenDeliveryDateService.openModal();
            return [
              CartActions.deliveryFrozenActionsError(),
              ClientActions.loadFrozenVisitDatesError(),
            ];
          }),
        ),
      ),
    ),
  );

  setFrozenOperationDateAndAddProduct$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CartActions.setFrozenOperationDateAndAddProduct),
      mergeMap((props) =>
        this.visitPlanService.setOperationDate('deliveryfrozen').pipe(
          switchMap(() => {
            this.cartService.updateDeliveryProducts();
            return [
              CartActions.setFrozenOperationDateAndAddProductSuccess(),
              CartActions.upsertProduct({ product: props.product }),
            ];
          }),
          catchError(() => {
            return of(CartActions.setFrozenOperationDateAndAddProductError());
          }),
        ),
      ),
    ),
  );

  setFrozenOperationDate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CartActions.setFrozenOperationDate),
      mergeMap((props) =>
        this.visitPlanService.setOperationDate('deliveryfrozen').pipe(
          switchMap(() => {
            return [CartActions.setFrozenOperationDateSuccess()];
          }),
          catchError(() => {
            return of(CartActions.setFrozenOperationDateError());
          }),
        ),
      ),
    ),
  );

  addFavoriteProduct$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CartActions.addFavoriteProduct),
      mergeMap((props) =>
        this.favoriteService.addFavoriteProduct(props.product).pipe(
          switchMap(() => {
            this.favoriteService.dispatachFavoriteAlert('ADD');
            return [CartActions.addFavoriteProductSuccess()];
          }),
          catchError(() => {
            this.favoriteService.dispatachFavoriteAlert('ERROR');
            return of(CartActions.addFavoriteProductError());
          }),
        ),
      ),
    ),
  );

  removeFavoriteProduct$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CartActions.removeFavoriteProduct),
      mergeMap((props) =>
        this.favoriteService.removeFavoriteProduct(props.product).pipe(
          switchMap(() => {
            this.favoriteService.dispatachFavoriteAlert('REMOVE');
            return [CartActions.removeFavoriteProductSuccess()];
          }),
          catchError(() => {
            return of(CartActions.removeFavoriteProductError());
          }),
        ),
      ),
    ),
  );

  showFridayMessageNotification$ = createEffect(() =>
    this.store.select(getFridayMessageShow).pipe(
      filter((show) => show === true),
      map(() => {
        this.cartService.dispatchNotification('PUSH');
        return;
      }),
    ),
    { dispatch: false },
  );
  

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
