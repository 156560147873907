import { Injectable, OnDestroy } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, Subscription } from 'rxjs';
import {
  catchError,
  map,
  mergeMap,
  shareReplay,
  switchMap,
  withLatestFrom
} from 'rxjs/operators';
import * as ClientActions from '../actions/client.actions';
import * as CartActions from '../actions/cart.actions';
import * as UserActions from '../actions/user.actions';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DraftRequestModalComponent } from '../../../shared/modals/draft-request-modal/draft-request-modal.component';
import { DraftRequestService } from '../../services/draft-request.service';
import { PaymentMethodService } from '../../services/payment-method.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Client } from '../../models/client.model';
import { MyAccountService } from 'src/app/pages/my-account/services/my-account.service';
import { VisitPlanService } from '../../services/visit-plan.service';
import { UserService } from '../../services/user.service';
import { Cart } from '../../models/cart.model';
import { ProductsService } from '../../services/products.service';
import { OrdersService } from 'src/app/pages/my-orders/services/orders.service';
import { DiscretionaryDiscountService } from '../../services/benefits.service';
import { UserInfo } from '../../models/user-info.model';
import { BERespModel } from '../../models/backend/BE-response.model';
import { Order } from '../../models/order.model';
import { MyCreditModel } from '../../models/my-account.model';
import { PaymentMethods } from '../../models/payment-method.model';
import { Draft } from '../../models/draft.model';
import { HomeService } from '../../services/home.service';
import { ModalsService } from '../../services/modals.service';
import { Locks } from '../../enums/locks.enum';
import { ClientService } from '../../services/client.service';
import { ExternalIntegration } from 'src/app/pages/external-integration/enums/external-integration.enum';
import { Banner } from '../../models/banner.model';

@Injectable({
  providedIn: 'root',
})
export class ClientEffects implements OnDestroy {
  private subscriptions = new Subscription();
  client: Client;
  cart: Cart;
  user: UserInfo;
  stepId: string;
  userId: number;

  constructor(
    private actions$: Actions,
    private draftRequestService: DraftRequestService,
    private modalService: NgbModal,
    private router: Router,
    private paymentMethodService: PaymentMethodService,
    private myAccountService: MyAccountService,
    private store: Store<{ client: Client; cart: Cart; user: UserInfo }>,
    private visitPlanService: VisitPlanService,
    private userService: UserService,
    private clientService: ClientService,
    private productsService: ProductsService,
    private ordersService: OrdersService,
    private discretionaryDiscountService: DiscretionaryDiscountService,
    private homeService: HomeService,
    private route: ActivatedRoute,
    private modalsService: ModalsService,
  ) {
    this.subscriptions.add(
      this.store.select('client').subscribe((client) => {
        this.client = client;
      }),
    );
    this.subscriptions.add(
      this.store.select('cart').subscribe((cart) => {
        this.cart = cart;
      }),
    );
    this.subscriptions.add(
      this.store.select('user').subscribe((user) => {
        this.user = user;
      }),
    );
    this.subscriptions.add(
      this.route.params.subscribe((params) => {
        this.stepId = params?.stepId;
      }),
    );
    this.subscriptions.add(
      this.route.queryParams.subscribe((params) => {
        this.userId = params?.user;
      }),
    );
  }

  loadDrafts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientActions.loadDrafts),
      mergeMap(() =>
        this.draftRequestService.getDrafts().pipe(
          map((res: Draft[]) => {
            if (
              (this.router.url.includes('nuevo-pedido') ||
                this.router.url.includes('mcc')) &&
              !this.client.hasLockOrder &&
              !this.client.lockOrderMissingPayment &&
              !this.router.url.includes('home') &&
              !this.router.url.includes('digital-promotions') &&
              !this.router.url.includes('history-exchange') &&
              !this.router.url.includes('pedido-sugerido')
            ) {
              this.modalService.open(DraftRequestModalComponent, {
                windowClass: 'ngbmodal-centered',
              });
            }
            return ClientActions.loadDraftsSuccess({ drafts: res });
          }),
          catchError(() => of(ClientActions.loadDraftsError())),
          shareReplay(1),
        ),
      ),
    ),
  );

  deleteDrafts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientActions.deleteDraft),
      mergeMap((props) =>
        this.draftRequestService.deleteDraft(props.draft.orderId).pipe(
          shareReplay(1),
          map(() => ClientActions.deleteDraftSuccess({ draft: props.draft })),
          catchError(() => of(ClientActions.deleteDraftError())),
        ),
      ),
    ),
  );

  loadPaymentMethod$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientActions.loadPaymentMethod),
      mergeMap(() =>
        this.paymentMethodService.getPaymentMethod().pipe(
          switchMap((res: PaymentMethods) => [
            ClientActions.lockOrderMissingPayment({
              lockOrderMissingPayment: false,
            }),
            ClientActions.loadPaymentMethodSuccess({
              paymentMethods: res.payments,
            }),
            CartActions.upsertpaymentMethod({ paymentMethod: res.payments[0] }),
          ]),

          catchError(() => {
            const isNewOrder = this.router.url.includes('nuevo-pedido');
            if (isNewOrder) this.modalsService.openPaymentContingencyModal();
            return [
              ClientActions.lockOrderMissingPayment({
                lockOrderMissingPayment: true,
              }),
              ClientActions.loadPaymentMethodError(),
            ];
          }),
          shareReplay(1),
        ),
      ),
    ),
  );

  loadCredits$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientActions.loadCredits),
      mergeMap(() =>
        this.myAccountService.getAvailableCredits().pipe(
          map((res: MyCreditModel[]) => {
            return ClientActions.loadCreditsSuccess({ credits: res });
          }),
          catchError(() => of(ClientActions.loadCreditsError())),
          shareReplay(1),
        ),
      ),
    ),
  );

  loadLastOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientActions.loadLastOrder),
      mergeMap(() =>
        this.ordersService.getClientLastOrder().pipe(
          map((res: Order) => {
            return ClientActions.loadLastOrderSuccess({ lastOrder: res });
          }),
          catchError(() => of(ClientActions.loadLastOrderError())),
          shareReplay(1),
        ),
      ),
    ),
  );

  loadOrders$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientActions.loadOrders),
      mergeMap(() =>
        this.ordersService.getClientOrders().pipe(
          map((res: Order[]) => {
            return ClientActions.loadOrdersSuccess({ orders: res });
          }),
          catchError(() => of(ClientActions.loadOrdersError())),
          shareReplay(1),
        ),
      ),
    ),
  );

  loadVisitDates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientActions.loadVisitDates),
      mergeMap(() =>
        this.visitPlanService.getClientVisitPlan().pipe(
          switchMap((res: BERespModel) => [
            ClientActions.loadVisitDatesSuccess({
              visitDates: res.data,
              visitDate: res.data[0].visitDate,
            }),
            CartActions.updateVisitDateSuccess({ date: res.data[0].visitDate }),
            CartActions.updateOffRoute({ offRoute: res.data[0].offRoute }),
            ClientActions.setClientHasLockOrder({ hasLockOrder: false }),
          ]),
          catchError(() => {
            return [
              ClientActions.loadOrders(),
              ClientActions.loadPaymentMethod(),
              ClientActions.loadCredits(),
              ClientActions.loadVisitDatesError(),
              CartActions.updateDeliveryDateSuccess({ date: '' }),
              CartActions.updateOffRoute({ offRoute: false }),
            ];
          }),
          shareReplay(1),
        ),
      ),
    ),
  );

  loadFrozenVisitDates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientActions.loadFrozenVisitDates),
      mergeMap(() =>
        this.visitPlanService.getClientVisitPlan(true).pipe(
          switchMap((res: BERespModel) => [
            ClientActions.loadFrozenVisitDatesSuccess({
              frozenVisitDates: res.data,
            }),
          ]),
          catchError(() => {
            return [ClientActions.loadFrozenVisitDatesError()];
          }),
          shareReplay(1),
        ),
      ),
    ),
  );

  updateVisitDateSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CartActions.updateVisitDateSuccess),
      mergeMap(() =>
        this.visitPlanService.setOperationDate().pipe(
          switchMap(() => {
            let actionsToDispatch = [];
            const isIntegration = this.router.url.includes('mcc');
            const isExternalUser = this.user.authValue && this.user.origin === ExternalIntegration.KOBOSS

            if (
              !this.router.url.includes('mi-saldo') &&
              !this.router.url.includes('mis-pedidos') &&
              !this.router.url.includes('home')
            ) {
              actionsToDispatch.push(ClientActions.updateOperationDatesSuccess());

              // fix to prevent duplicated modal when isExternal buyer
              if (!isExternalUser) {
                actionsToDispatch.push(ClientActions.loadDrafts())
              }
              if (isIntegration) {
                actionsToDispatch.push(ClientActions.loadSuggestedProducts());
              }
            }

            if (this.router.url.includes('mi-saldo')) {
              actionsToDispatch = [
                ClientActions.loadPaymentMethod(),
                ClientActions.loadCredits(),
              ];
            }

            if (this.router.url.includes('mis-pedidos')) {
              actionsToDispatch = [
                ClientActions.updateOperationDatesSuccess(),
                ClientActions.loadOrders(),
              ];

              if (!isIntegration) {
                actionsToDispatch.push(ClientActions.loadDrafts());
              }
            }

            if (this.router.url.includes('home')) {
              actionsToDispatch = [ClientActions.loadSuggestedProducts()];

              if (!isIntegration) {
                actionsToDispatch.push(
                  ClientActions.updateOperationDatesSuccess(),
                );
              }
            }
            return actionsToDispatch;
          }),
          catchError(() => {
            return of(ClientActions.updateOperationDatesError());
          }),
          shareReplay(1),
        ),
      ),
    ),
  );

  updateOperationDatesSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientActions.updateOperationDatesSuccess),
      mergeMap(() =>
        this.visitPlanService.getInvoiceDeadline().pipe(
          map((res: BERespModel) => {
            return CartActions.updateInvoiceDeadline({
              invoiceDeadline: res.data.deadlineTime,
            });
          }),
          catchError(() => {
            return of(
              CartActions.updateInvoiceDeadline({ invoiceDeadline: '' }),
            );
          }),
          shareReplay(1),
        ),
      ),
    ),
  );

  loadInitClientSession$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientActions.loadInitClientSession),
      mergeMap(() =>
        this.userService.initClientSession().pipe(
          switchMap(() => [
            ClientActions.loadVisitDates(),
            ClientActions.loadInitClientSessionSuccess(),
          ]),
          catchError(() => {
            return of(ClientActions.loadInitClientSessionError());
          }),
          shareReplay(1),
        ),
      ),
    ),
  );

  loadSuggestedProducts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientActions.loadSuggestedProducts),
      mergeMap(() =>
        this.productsService.getSuggestedProducts(0, true, true).pipe(
          map((res: BERespModel) => {
            return ClientActions.loadSuggestedProductsSuccess({
              suggestedProducts: {
                products: res.data.suggestedProducts,
                amount: res.data.amount,
              },
            });
          }),
          catchError(() => of(ClientActions.loadSuggestedProductsError())),
          shareReplay(1),
        ),
      ),
    ),
  );

  loadSuggestedProductsWhitOutAmount$ = createEffect(() =>
  this.actions$.pipe(
    ofType(ClientActions.loadSuggestedProductsWhitOutAmount),
    withLatestFrom(this.store.select(state => state.client.data?.suggestedProducts?.amount)),
    switchMap(([,amount]) =>
      this.productsService.getSuggestedProducts(0, true, true, false, false).pipe(
        map((res: BERespModel) => 
          ClientActions.loadSuggestedProductsSuccess({
            suggestedProducts: {
              products: res.data.suggestedProducts,
              amount: amount || 0,
            },
          })
        ),
        catchError(() => of(ClientActions.loadSuggestedProductsError()))
      )
    )
  )
);


  loadDiscountProducts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientActions.loadDiscountProducts),
      mergeMap(() =>
        this.productsService.getPortfolioWithDiscountByClient(true, {}, 0).pipe(
          shareReplay(1),
          map((res: BERespModel) => {
            return ClientActions.loadDiscountProductsSuccess({
              discountProducts: res.data,
            });
          }),
          catchError(() => of(ClientActions.loadDiscountProductsError())),
        ),
      ),
    ),
  );

  loadDiscretionaryDiscount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientActions.loadDiscretionaryDiscount),
      mergeMap(() =>
        this.discretionaryDiscountService.getBenefitsClient().pipe(
          map((res) => {
            return ClientActions.loadDiscretionaryDiscountSuccess({
              discounts: res.data,
            });
          }),
          catchError(() => of(ClientActions.loadDiscretionaryDiscountError())),
          shareReplay(1),
        ),
      ),
    ),
  );

  updateClient$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientActions.updateClient),
      mergeMap((props) =>
        this.userService.getUserInfo(this.user.cognitoUserName).pipe(
          switchMap((res: BERespModel) => {
            const client = res.data.clients.find(
              (client) => client.clientId === props.client.clientId,
            );
            let actionsToDispatch = [];

            if (
              client.locks?.some((lock) => lock.description === Locks.PPD_USER)
            ) {
              actionsToDispatch = [
                UserActions.updateUserSuccess({ user: res.data }),
                ClientActions.updateClientSuccess({ client }),
              ];
            } else {
              actionsToDispatch = [
                UserActions.updateUserSuccess({ user: res.data }),
                ClientActions.updateClientSuccess({ client }),
                ClientActions.loadInitClientSession(),
              ];
            }

            if (this.router.url.includes('home')) {
              actionsToDispatch.push(ClientActions.loadDrafts());
              actionsToDispatch.push(ClientActions.loadLastOrder());
              actionsToDispatch.push(ClientActions.loadPaymentMethod());
              actionsToDispatch.push(
                ClientActions.loadDiscountProductsForOffert({
                  isDiscount: true,
                }),
              );
            }

            if (this.router.url.includes('mis-pedidos')) {
              actionsToDispatch.push(ClientActions.loadPaymentMethod());
            }

            actionsToDispatch.push(ClientActions.loadBannerImages({ clientId: client.clientId }))

            return actionsToDispatch;
          }),
          catchError(() => of(ClientActions.updateClientError())),
          shareReplay(1),
        ),
      ),
    ),
  );

  checkClientLocks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientActions.checkClientLocks),
      mergeMap(() =>
        this.visitPlanService.getClientVisitPlan().pipe(
          switchMap((res: BERespModel) => {
            let actionsToDispatch = [];
            actionsToDispatch = [
              ClientActions.checkClientLocksSuccess(),
              ClientActions.setClientHasLockOrder({ hasLockOrder: false }),
              ClientActions.setLockType({ lockType: '' }),
              ClientActions.updateHasCreditLock({ hasCreditLock: false }),
              ClientActions.loadVisitDatesSuccess({
                visitDates: res.data,
                visitDate: res.data[0].visitDate,
              }),
            ];
            if (
              !this.cart.visitDate ||
              !res.data.some(
                (date) =>
                  date.visitDate ===
                  new Date(this.cart.visitDate).toISOString(),
              )
            ) {
              actionsToDispatch.push(
                CartActions.updateDeliveryDate({ date: res.data[0] }),
              );
            }
            return actionsToDispatch;
          }),
          catchError(() => {
            return [
              ClientActions.checkClientLocksError(),
              ClientActions.loadVisitDatesError(),
            ];
          }),
          shareReplay(1),
        ),
      ),
    ),
  );

  loadClient$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientActions.loadClient),
      switchMap(() =>
        this.clientService.getClientInfo().pipe(
          map((res: BERespModel) => {
            return ClientActions.loadClientSuccess({
              client: res.data,
            });
          }),
          catchError(() => of(ClientActions.loadClientError())),
        ),
      ),
    ),
  );

  loadDiscountProductsForOffert$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientActions.loadDiscountProductsForOffert),
      mergeMap((action) => {
        return this.productsService
          .getPortfolioWithDiscountByClient(
            false,
            { isDiscount: action.isDiscount },
            0,
          )
          .pipe(
            shareReplay(1),
            map((res: BERespModel) => {
              return ClientActions.loadDiscountProductsForOffertSuccess({
                discountProductsforOffert: res.data,
              });
            }),
            catchError(() =>
              of(ClientActions.loadDiscountProductsForOffertError()),
            ),
          );
      }),
    ),
  );

  loadBannerImages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientActions.loadBannerImages),
      mergeMap((props) =>
        this.homeService.getBannerImages(props.clientId).pipe(
          map((res: BERespModel) => ClientActions.loadBannerImagesSuccess({ banners: res.data })),
          catchError(() => of(ClientActions.deleteDraftError())),
        ),
      ),
    ),
  );

  updateNavigationControlsBanner$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientActions.loadBannerImagesSuccess), 
      map(({ banners }) => {
        const showNavigationControls = banners.length > 1;
        return ClientActions.updateNavigationControlsBanner({
          showNavigationIndicators: showNavigationControls,
          showNavigationArrows: showNavigationControls,
        });
      }),
    ),
  );

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
