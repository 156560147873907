import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { EventSelectDateBtn } from '../enums/select-delivery-dates.enum';

const MODAL_OPEN = true;
const MODAL_CLOSED = false;

@Injectable({
  providedIn: 'root'
})
export class ModalMobileDeliveryDateService {
  isCloseModal$ = new Subject<EventSelectDateBtn>;
  currentShowModal = false;
  canCloseWithShadow = true;

  openModal(): void {
    this.currentShowModal = MODAL_OPEN;
  }

  closeModal(btnEvent: EventSelectDateBtn): void {
    this.isCloseModal$.next(btnEvent);
    this.currentShowModal = MODAL_CLOSED;
  }

  resetCanCloseWithShadow(): void {
    this.canCloseWithShadow = true;
  }
}