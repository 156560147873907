import { CountryConfig, FEATURES } from '../utils/env.model';

export const configAR: CountryConfig = {
  awsUserPoolId: 'us-east-1_Wzo4v8ew5',
  awsClientId: '1ec9l2759gdgas53hjoqqbstat',
  awsClientIdMCC: 'cbktbih19m6dsrpc7hhcimnh',
  awsAuthFlow: 'USER_SRP_AUTH',
  oauthDomain: 'app.auth.dev.miportalb2b.com',
  identityPoolId: 'us-east-1:290533d8-6677-4307-970a-6cc2498f86ff',
  key: 'AR',
  label: 'Argentina',
  lang: 'es',
  organizationId: '3046',
  cpgId: '001',
  phoneRegex: new RegExp('^[0-9]{10,11}$'),
  homeStyle: 2,
  fiscalIdRegex: [new RegExp('^[0-9]{10,11}$')],
  maxlengthFiscalId: 11,
  minPurchase: 4000,
  hasOffRoute: true,
  productsPerPagePortfolio: 30,
  visitPlanLength: 7,
  digitalPaymentsUrl: 'https://dev.pagos.miportalb2b.com', 
  showMinAmountByDates: true,
  showGreenTextMinAmount: true,
  availableFeatures: [
    FEATURES.CATEGORY_CAROUSEL,
    FEATURES.CATEGORY_BUTTON_CAROUSEL,
    FEATURES.DIGITAL_PROMOTIONS,
    FEATURES.SCANNER_QR,
    FEATURES.NEWSLETTERS,
    FEATURES.LOADING_SCREEN,
  ],
};

export const configBR: CountryConfig = {
  awsUserPoolId: 'us-east-1_Wzo4v8ew5',
  awsClientId: '1ec9l2759gdgas53hjoqqbstat',
  awsAuthFlow: 'USER_SRP_AUTH',
  oauthDomain: 'app.auth.dev.miportalb2b.com',
  identityPoolId: 'us-east-1:290533d8-6677-4307-970a-6cc2498f86ff',
  key: 'BR',
  label: 'Brasil',
  lang: 'pt',
  organizationId: '3048',
  cpgId: '001',
  phoneRegex: [new RegExp('^[0-9]{8,10}$')],
  homeStyle: 1,
  fiscalIdRegex: [
    new RegExp(
      '^([0-9]{3}.?[0-9]{3}.?[0-9]{3}-?[0-9]{2}|[0-9]{2}.?[0-9]{3}.?[0-9]{3}/?[0-9]{4}-?[0-9]{2})$',
    ),
  ],
  maxlengthFiscalId: 14,
  minPurchase: 150,
  hasOffRoute: false,
  productsPerPagePortfolio: 30,
  visitPlanLength: 7,
  surveyId: '10e27b6d-bdea-448c-96a6-467fc2cf1c98',
  digitalPaymentsUrl: 'https://dev.pagos.miportalb2b.com',
  showMinAmountByDates: true,
  showGreenTextMinAmount: false,
  availableFeatures: [
    FEATURES.SOCIAL_LOGIN,
    FEATURES.CATEGORY_CAROUSEL,
    FEATURES.CATEGORY_BUTTON_CAROUSEL,
    FEATURES.DIGITAL_PAYMENTS,
    FEATURES.MY_WALLET,
    FEATURES.NEWSLETTERS,
    FEATURES.LOADING_SCREEN,
    FEATURES.CRM,
  ],
};

export const configCL: CountryConfig = {
  awsUserPoolId: 'us-east-1_Wzo4v8ew5',
  awsClientId: '1ec9l2759gdgas53hjoqqbstat',
  awsAuthFlow: 'USER_SRP_AUTH',
  awsClientIdMCC: 'cbktbih19m6dsrpc7hhcimnh',
  oauthDomain: 'app.auth.dev.miportalb2b.com',
  identityPoolId: 'us-east-1:290533d8-6677-4307-970a-6cc2498f86ff',
  key: 'CL',
  label: 'Chile',
  lang: 'es',
  organizationId: '3043',
  cpgId: '001',
  phoneRegex: new RegExp('^[0-9]{9}$'),
  homeStyle: 2,
  fiscalIdRegex: 'RUT',
  maxlengthFiscalId: 10,
  hasOffRoute: false,
  minPurchase: 0,
  minPurchaseFrozen: 0,
  productsPerPagePortfolio: 30,
  visitPlanLength: 7,
  surveyId: "2cee53f1-780c-496a-bd9d-8e9f5e722e65",
  digitalPaymentsUrl: 'https://dev.pagos.miportalb2b.com',
  showMinAmountByDates: false,
  showGreenTextMinAmount: false,
  availableFeatures: [
    FEATURES.DOWNLOAD_INVOICE,
    FEATURES.THANOS_ORDER_DETAIL,
    FEATURES.CRM,
    FEATURES.SOCIAL_LOGIN,
    FEATURES.CATEGORY_CAROUSEL,
    FEATURES.CATEGORY_ICON_CAROUSEL,
    FEATURES.DIGITAL_PAYMENTS,
    FEATURES.MY_WALLET,
    FEATURES.UPDATE_MIN_PURCHASE,
    FEATURES.PAYMENT_METHODS_BY_CATEGORY_ORDER_DETAIL,
    FEATURES.NEWSLETTERS,
    FEATURES.LOADING_SCREEN,
  ],
};

export const configPY: CountryConfig = {
  awsUserPoolId: 'us-east-1_PchG59ebI',
  awsClientId: '3pg6fuovedk8q0vme0l1juba29',
  awsAuthFlow: 'USER_PASSWORD_AUTH',
  oauthDomain: 'dev-mcc-py.auth.us-east-1.amazoncognito.com',
  identityPoolId: 'us-east-1:71fd9b01-cae1-43f9-8f5a-1cdebb2623f9',
  key: 'PY',
  label: 'Paraguay',
  lang: 'es',
  minPurchase: 100000,
  hasOffRoute: true,
  organizationId: '3049',
  cpgId: '001',
  phoneRegex: new RegExp('^[0-9]{8,10}$'),
  homeStyle: 1,
  fiscalIdRegex: [
    new RegExp('^[0-9]{6,10}[-]{1}[0-9a-zA-Z]{1}$'),
    new RegExp('^[0-9]{6,8}$'),
  ],
  maxlengthFiscalId: 10,
  productsPerPagePortfolio: 30,
  visitPlanLength: 7,
  digitalPaymentsUrl: 'https://dev.pagos.miportalb2b.com',
  showMinAmountByDates: true,
  showGreenTextMinAmount: false,
  availableFeatures: [
    FEATURES.CATEGORY_CAROUSEL,
    FEATURES.CATEGORY_ICON_CAROUSEL,
    FEATURES.DOWNLOAD_INVOICE,
    FEATURES.SOCIAL_LOGIN,
    FEATURES.DIGITAL_PAYMENTS,
    FEATURES.MY_WALLET,
    FEATURES.LOYALTY,
    FEATURES.DIGITAL_PROMOTIONS,
    FEATURES.SCANNER_QR,
    FEATURES.NEWSLETTERS,
    FEATURES.COMMERCE_FANTASY_NAME,
    FEATURES.LOADING_SCREEN,
    FEATURES.CRM,
  ],
};
